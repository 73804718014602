import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header2 from "./Header2";
import { useDispatch } from "react-redux";
import { postPackageBooking } from "../reduxToolKit/slices/bookPackageApiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { commissionApi } from "../reduxToolKit/slices/commissionApiSlice";


const initialState = {
  checkboxes: [false, false, false],
  paymentType: "",
  mainUser: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    panCard: "",
    nation: "",
  },
  special: "",
  enterSpecial: "",

  errorMsg: {},
};
const BookPackage = () => {

  const navigate = useNavigate()
  const [iState, updateState] = useState(initialState);
  const [commision, setCommision] = useState([])
  const [CustomerType, SetCustomerType] = useState("");
  const { paymentType, mainUser, errorMsg, special, enterSpecial, checkboxes } = iState;
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [person_details, SetPerson_details] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };
  const scrollToBottom = () => {
    const element = document.getElementById('BottomErrror')
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

  };

  const scrollToTop = () => {

    window.scrollTo(0, 0)
  }
  const handleValidation = () => {
    let formIsValid = true;

    let errorMsg = {};

    // if (!state?.newstate?.city) {
    //   return false;
    // }
    // if (!state?.newstate?.date) {
    //   return false;
    // }

    // if (!state?.adults) {
    //   return false;
    // }

    // if (!state?.newstate?.transferType) {
    //   return false;
    // }

    // if (!state?.newstate?.hotelType) {
    //   return false;
    // }

    // if (!paymentType) {
    //   return false;
    // }

    if (!paymentType) {
      errorMsg.paymentType = "* Please select paymentType";
      formIsValid = false;
    }

    if (!mainUser.phoneNumber) {
      scrollToBottom();


      errorMsg.phoneNumber = "* Please enter contact number";
      formIsValid = false;
    } else if (mainUser.phoneNumber.length != 10) {
      scrollToBottom();

      errorMsg.phoneValid = "* Please enter valid number";
      formIsValid = false;
    }

    if (!mainUser.firstName) {
      scrollToBottom();

      errorMsg.firstName = "* Please enter firstName";
      formIsValid = false;
    }

    if (!mainUser.lastName) {
      scrollToBottom();

      errorMsg.lastName = "* Please enter lastName";
      formIsValid = false;
    }

    if (!mainUser.address) {
      scrollToBottom();

      errorMsg.address = "* Please enter address";
      formIsValid = false;
    }

    if (!mainUser.panCard) {
      scrollToBottom();

      errorMsg.panCard = "* Please enter panCard no.";
      formIsValid = false;
    }

    if (!mainUser.nation) {
      scrollToBottom();

      errorMsg.nation = "* Please enter nation.";
      formIsValid = false;
    }

    if (CustomerType == "all") {
      scrollToBottom();

      if (person_details?.some((item) => item?.firstName == "")) {
        errorMsg.firstNameAll = "* Please enter first name";
        formIsValid = false;
      }

      if (person_details?.some((item) => item?.lastName == "")) {
        scrollToBottom();

        errorMsg.lastNameAll = "* Please enter last name";
        formIsValid = false;
      }
    }


    if (checkboxes?.some((item) => item == false)) {
      scrollToTop()
      errorMsg.checkboxes = "*  All above checkboxes are mandatory";
      formIsValid = false;

    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const booknow = () => {


    let userDetails
    if (CustomerType == "all") {
      userDetails = person_details.push(mainUser)

    }
    else {
      userDetails = [mainUser]
    }
    const data = {
      packageId: state?.packageId,
      bookingType: "packages",
      departure: state?.newstate?.city,
      //selectedDate: state?.newstate?.date,
      selectedDate: "27-3-2023",
      adult: state?.adults,
      // "child": 2,
      transfer: state?.newstate?.tranferType,
      hotelType: state?.newstate?.hotelType,
      totalPrice: String(state?.priceData * state?.adults),
      gst: 15,
      transictionId: "oiuy",
      paymentType: paymentType,
      userDetails

    };
    console.log('book now validation', handleValidation())
    if (handleValidation()) {
      console.log(handleValidation());
      dispatch(postPackageBooking(data)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Package booking sucessfull", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState(initialState);

          navigate(`/hotelListingHistory/$${paymentType}`)
        }
      });
    }
  };

  const fromDate = moment(state?.newstate?.date).add(
    state?.packageData?.numberOfNights?.totalNumberofNights + 1,
    "days"
  );

  const handlePassenger = () => {
    SetCustomerType("all");
    if (person_details.length < 1) {

      for (let i = 0; i < state?.adults; i++) {
        let temp = person_details;
        temp.push({ firstName: "", lastName: "" });

        SetPerson_details(temp);
      }

    }
  };

  const handleCustomChange = (e) => {
    const { name, value } = e.target;

    if (name == "phoneNumber") {

      if (!/^[0-9]{0,10}$/.test(value) || e.target.value == "e") return;
      {
        updateState({
          ...iState,
          mainUser: { ...mainUser, [name]: value },
        });
      }
    }
    else {

      updateState({
        ...iState,
        mainUser: { ...mainUser, [name]: value },
      });
    }

  };


  const handleCheck = (e, i) => {
    let temp = checkboxes
    temp[i] = e.target.checked
    updateState({
      ...iState,

      checkboxes: temp
    });
  };




  useEffect(() => {
    dispatch(commissionApi())
      .then((res) => {
        console.log(res, "CCCCCCCCCCCCc")

        let temp = res?.payload?.data?.[0]?.commision?.filter((item) => item?.bookingType == "packages")

        setCommision(temp)
      });
    window.scroll(0, 0)

  }, [])



  console.log(state,'================>')



  return (
    <div>
      <Header2 />
      <main id="main" className="ProductMain">
        <section>
          <div className="FilterProductsArea">
            {/* <div class="d-flex my-5">
              <h5 class="" data-toggle="modal" data-target="#BookingConfirmed">BookingConfirmed</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5 class="" data-toggle="modal" data-target="#DeleteModal">Delete Modal</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5 class="" data-toggle="modal" data-target="#CountryModal">Country Modal</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5 class="" data-toggle="modal" data-target="#">CityTravelModal</h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div> */}
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="BookHotelDetailsArea">
                  <div className="HotelNameRatings">
                    <figure>
                      <img
                        style={{ width: "80px", borderRadius: "5px" }}
                        src={state?.packageData?.packageBanner?.[0]}
                      />
                    </figure>
                    <h3>{state?.packageData?.packageTitle}</h3>
                    <h6>
                      {" "}
                      {
                        state?.packageData?.numberOfNights?.totalNumberofNights
                      }{" "}
                      Days{" "}
                      {state?.packageData?.numberOfNights?.totalNumberofNights +
                        1}{" "}
                      Nights
                    </h6>
                    <ul>
                      <li>
                        <span>
                          <img
                            src={require("../assests/images/van_icon.png")}
                          />
                        </span>{" "}
                        {state?.packageData?.packageTransportationType}
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../assests/images/winter_icon.png")}
                          />
                        </span>{" "}
                        {state?.packageData?.season}
                        Special
                      </li>
                    </ul>
                  </div>
                  <div className="BookingHotelChecks">
                    <div>
                      <p>From</p>
                      <h5>{moment(state?.state?.date)?.format("ll")}</h5>
                    </div>
                    <div>
                      <figure>
                        <img
                          src={require("../assests/images/check_in_out_img.png")}
                        />
                      </figure>
                    </div>
                    <div>
                      <p>To</p>
                      <h5>{moment(state?.state?.date).add(state?.packageData?.numberOfNights?.totalNumberofNights + 1,"days")?.format("ll")}</h5>
                    </div>
                  </div>
                  <div className="OverviewDetails" id="OverviewDetails">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          href="#Overview"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          Overview
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#Itinerary"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Itinerary
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#Sightseeing"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Sightseeing
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="Overview">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: state?.packageData?.packageOverview,
                          }}
                        ></div>
                      </div>
                      <div className="tab-pane fade" id="Itinerary">
                        <h2>Day wise travel itinerary</h2>
                        {state?.packageData?.itinerary?.map((item, i) => (
                          <div class="IdBox">
                            <h3>
                              Day {i + 1}:<span>{item?.title}</span>
                            </h3>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.descriptionItinerary,
                                }}
                              ></div>
                            </p>
                            <p>
                              Included Meal:{" "}
                              {item?.MealsInclusions?.map(
                                (ele, index) =>
                                  `${index == 0 ? "" : ","} ${ele}`
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="tab-pane fade" id="Sightseeing">
                        {state?.packageData?.sightSeeing?.map((item, index) => (
                          <div
                            class="tab-pane fade show active"
                            id={`sight${index}`}
                          >
                            {item?.sightInfo?.map((ele) => (
                              <div class="SdMain">
                                <div class="SdLeft">
                                  <figure>
                                    <img src={ele?.sightSeeingBanner?.[0]} />
                                  </figure>
                                </div>
                                <div class="SdRight">
                                  <h2>{ele?.sightseeingTitle} </h2>
                                  <p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.sightseeingDescription,
                                      }}
                                    ></div>
                                  </p>
                                  {/* <a href="javascript:void(0);" class="EfiBtn">
                           Read More
                         </a> */}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="EnterCustomerDetails">
                  <h4>Enter Customer Details</h4>
                  <div class="CommonTabscart">
                    <div class="nav" role="tablist">
                      <div class="nav-item nav-item">
                        <a
                          role="tab"
                          onClick={() => SetCustomerType("single")}
                          data-rr-ui-event-key="LeadPassenger"
                          id="react-aria9575245955-:r3:-tab-LeadPassenger"
                          aria-controls="react-aria9575245955-:r3:-tabpane-LeadPassenger"
                          aria-selected="true"
                          class={
                            CustomerType == "single"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          tabindex="0"
                        >
                          Enter the lead passenger detail only
                        </a>
                      </div>
                      <div class="nav-item">
                        <a
                          role="tab"
                          onClick={handlePassenger}
                          data-rr-ui-event-key="AllPassenger"
                          id="react-aria9575245955-:r3:-tab-AllPassenger"
                          aria-controls="react-aria9575245955-:r3:-tabpane-AllPassenger"
                          aria-selected="false"
                          tabindex="-1"
                          class={
                            CustomerType == "all"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          {" "}
                          Enter detail of all passenger
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content">
                    <div
                      role="tabpanel"
                      id="react-aria9575245955-:r3:-tabpane-LeadPassenger"
                      aria-labelledby="react-aria9575245955-:r3:-tab-LeadPassenger"
                      class="fade tab-pane active show"
                    ></div>
                    <div
                      role="tabpanel"
                      id="react-aria9575245955-:r3:-tabpane-AllPassenger"
                      aria-labelledby="react-aria9575245955-:r3:-tab-AllPassenger"
                      class="fade tab-pane"
                    ></div>
                  </div>
                </div>

                <div className="EnterCustomerDetails" id="BottomErrror">
                  <h4>Enter Customer Details</h4>
                  <h6>Enter the lead passenger data only</h6>
                  <form>
                    <div className="form-group ForName">
                      <div className="NameSelectBox">
                        <select>
                          <option>Mr.</option>
                        </select>
                        <span>
                          <i className="fa fa-angle-down" aria-hidden="true" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleCustomChange}
                        name="firstName"
                        value={mainUser.firstName}
                        placeholder="First Name"
                      />
                    </div>

                    {errorMsg.firstName && !mainUser.firstName && (
                      <p style={{ color: "red" }}>{errorMsg.firstName}</p>
                    )}
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={handleCustomChange}
                        name="lastName"
                        value={mainUser.lastName}
                        className="form-control"
                        placeholder="Last Name"
                      />

                      {errorMsg.lastName && !mainUser.lastName && (
                        <p style={{ color: "red" }}>{errorMsg.lastName}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        onChange={handleCustomChange}
                        name="phoneNumber"
                        value={mainUser.phoneNumber}
                        className="form-control"
                        placeholder="Enter Phone Number"
                      />

                      {errorMsg.phoneNumber && !mainUser.phoneNumber && (
                        <p style={{ color: "red" }}>{errorMsg.phoneNumber}</p>
                      )}
                      {errorMsg.phoneValid && (
                        <p style={{ color: "red" }}>{errorMsg.phoneValid}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={handleCustomChange}
                        name="address"
                        value={mainUser.address}
                        className="form-control"
                        placeholder="Enter  Address"
                      />
                      {errorMsg.address && !mainUser.address && (
                        <p style={{ color: "red" }}>{errorMsg.address}</p>
                      )}{" "}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={handleCustomChange}
                        name="nation"
                        value={mainUser.nation}
                        className="form-control"
                        placeholder="Enter Nation"
                      />

                      {errorMsg.nation && !mainUser.nation && (
                        <p style={{ color: "red" }}>{errorMsg.nation}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={handleCustomChange}
                        name="panCard"
                        value={mainUser.panCard}
                        className="form-control"
                        placeholder="Enter  PAN number"
                      />
                      {errorMsg.panCard && !mainUser.panCard && (
                        <p style={{ color: "red" }}>{errorMsg.panCard}</p>
                      )}
                    </div>
                  </form>
                </div>

                {CustomerType == "all" &&
                  person_details?.map((item, i) => (
                    <div className="EnterCustomerDetails">
                      <h4>Enter Customer Details</h4>
                      <h6>Enter other customer details</h6>
                      <form>
                        <div className="form-group ForName">
                          <div className="NameSelectBox">
                            <select>
                              <option>Mr.</option>
                            </select>
                            <span>
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              person_details[i].firstName = e.target.value;
                              let value = [...person_details];
                              SetPerson_details(value);
                            }}
                            placeholder="First Name"
                          />
                        </div>
                        {errorMsg.firstNameAll && !item.firstName && (
                          <p style={{ color: "red" }}>{errorMsg.firstNameAll}</p>
                        )}
                        <div className="form-group">
                          <input
                            type="text"
                            value={item?.lastName}
                            onChange={(e) => {
                              person_details[i].lastName = e.target.value;
                              let value = [...person_details];
                              SetPerson_details(value);
                            }}
                            className="form-control"
                            placeholder="Last Name"
                          />
                        </div>
                        {errorMsg.lastNameAll && !item.lastName && (
                          <p style={{ color: "red" }}>{errorMsg.lastNameAll}</p>
                        )}
                      </form>
                    </div>
                  ))}
              </div>
              <div className="col-md-6">
                <div className="CancellationPolicyArea">
                  <div className="Heading">
                    <h3>Cancellation Policy</h3>
                  </div>
                  <div className="CancelationCharges">
                    <h5>Cancelation charges</h5>
                    <p>31 days or before - Full deposit</p>
                    <p>20-25 days - 35% of the tour price</p>
                    <p>20-25 days - 35% of the tour price</p>
                    <p>31 days or before - Full deposit</p>
                  </div>
                  <div className="SpecialRequest">
                    <h5>Special Request</h5>
                    <select onChange={handleChange} value={special} name="special" className="form-control">
                      <option value={'Birthday'}>Birthday</option>
                      <option value={'Wedding Anniversary'}>Wedding Anniversary</option>
                      <option value={'Honeymooners'}>Honeymooners</option>

                      <option value={'Any Special Request'}>Any Special Request</option>

                    </select>
                    {special == "Any Special Request" && <input type="text" className="form-control" onChange={handleChange} value={enterSpecial} name="enterSpecial" placeholder="Enter Special Request" />}

                    <label className="CheckBox">
                      I have read <strong>Terms &amp; conditions</strong> and{" "}
                      <strong>Cancellation Policies</strong>
                      <input type="checkbox" onChange={(e) => handleCheck(e, 0)} />
                      <span className="checkmark" />
                    </label>
                    <label className="CheckBox">
                      The availabilty is ony 90% accurate as availability is not
                      connected to the hotel registration system.
                      <input type="checkbox" onChange={(e) => handleCheck(e, 1)} />
                      <span className="checkmark" />
                    </label>
                    <label className="CheckBox">
                      Book Online - (An Additional 2.5 % Charge Will be
                      applied.)
                      <input type="checkbox" onChange={(e) => handleCheck(e, 2)} />
                      <span className="checkmark" />
                    </label>

                    {errorMsg.checkboxes && checkboxes?.some((item) => item == false) && (
                      <p style={{ color: "red" }}>{errorMsg.checkboxes}</p>
                    )}
                  </div>


                  <div className="FullPartPayment">
                    <div className="d-flex justify-content-between">
                      <div className="form-group mb-0">
                        <label className="Radio active">
                          {" "}
                          Full Payment
                          <input
                            type="radio"
                            name="paymentType"
                            onChange={handleChange}
                            value="fullPayment"
                          />
                          <span className="checkmark" />
                        </label>
                        {/* <span>{state?.adults}Person * {state?.priceData} Price </span>


                      <h3>
                      <i class="fa fa-inr"></i> {(state?.adults * state?.priceData).toFixed(2)}
                      </h3>
                      <p>{commision?.[0]?.gst}% gst </p>
                      <p>{commision?.[0]?.serviceCharge}% Charges </p> */}


                      </div>
                    </div>
                    <div className="NewTablePrice mb-4">
                      {
                        paymentType === 'fullPayment' && <table>
                          {/* <thead><tr><th colspan="2">Price Details</th></tr></thead> */}
                          <tbody>
                            <tr>
                              <td>Total Amount</td><td>INR {Number(state?.priceData) * Number(state?.adults)}</td>
                            </tr>
                            <tr><td>Per Person</td><td>INR {Number(state?.priceData)}</td>
                            </tr>
                            <tr><td>GST</td><td>{commision?.[0]?.gst} %</td></tr>
                            <tr><td>Charges</td><td> {Number(state?.priceData) * Number(state?.adults) *commision?.[0]?.serviceCharge /100} </td></tr>
                          </tbody><tfoot><tr><td>Total</td><td>INR {Number(state?.priceData) * Number(state?.adults)  +Number(state?.priceData) * Number(state?.adults) *commision?.[0]?.gst/100 +Number(state?.priceData) * Number(state?.adults) *commision?.[0]?.serviceCharge /100} </td></tr></tfoot></table>
                      }

                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-group mb-0">
                        <label className="Radio">
                          {" "}
                          Part Payment
                          <input
                            type="radio"
                            name="paymentType"
                            onChange={handleChange}
                            value="partPayment"
                          />
                          <span className="checkmark" />
                        </label>

                        {/* <span>{state?.adults}Person * <strike>{state?.priceData}</strike> Price </span>
                      <h3>INR {((state?.adults * state?.priceData) / 3).toFixed(2)}</h3>
                      <p>{commision?.[0]?.gst}% gst </p>
                      <p>{commision?.[0]?.serviceCharge}% Charges </p> */}



                      </div>
                    </div>
                    <div className="NewTablePrice mb-4">
                      {
                        paymentType === 'partPayment' && <table>
                          {/* <thead><tr><th colspan="2">Price Details</th></tr></thead> */}
                          <tbody>
                            <tr>
                              <td>Total Amount</td><td>INR <span>{state?.adults}Person * {((state?.adults * state?.priceData) / 3).toFixed(2)} Price </span></td>
                            </tr>
                            <tr><td>Per Person</td><td>INR {((state?.adults * state?.priceData) / 3).toFixed(2)}</td>
                            </tr>
                            <tr><td>GST</td><td>{commision?.[0]?.gst}%</td></tr>
                            <tr><td>Charges</td><td>INR {(((state?.adults * state?.priceData) / 3)*commision?.[0]?.serviceCharge/100).toFixed(2)}</td></tr>
                          </tbody><tfoot><tr><td>Total</td><td>INR {(parseInt(state?.priceData) * parseInt(state?.adults)/3 +  parseFloat((state?.adults * parseInt(state?.priceData)) / 3) + (((state?.adults * state?.priceData) / 3)*commision?.[0]?.gst/100) +((state?.adults * state?.priceData) / 3)*commision?.[0]?.serviceCharge/100).toFixed(2)}</td></tr></tfoot></table>
                      }

                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        {" "}
                        Hold for 24 hours
                        <input
                          type="radio"
                          name="paymentType"
                          onChange={handleChange}
                          value="paymentHold"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="NewTablePrice mb-4">
                      {
                        paymentType === 'paymentHold' && <table>
                          {/* <thead><tr><th colspan="2">Price Details</th></tr></thead> */}
                          <tbody>
                            <tr>
                              <td>Total Amount</td><td>INR 181.30</td>
                            </tr>
                            <tr><td>Per Person</td><td>INR 0</td>
                            </tr>
                            <tr><td>GST</td><td>12%</td></tr>
                            <tr><td>Charges</td><td>INR 40</td></tr>
                          </tbody><tfoot><tr><td>Total</td><td>INR 181.30</td></tr></tfoot></table>
                      }

                    </div>
                    {errorMsg.paymentType && !paymentType && (
                      <p style={{ color: "red" }}>{errorMsg.paymentType}</p>
                    )}
                    <div className="form-group">
                      <a
                        className="ConfirmBookigBtn"
                        style={{ fontColor: "" }}
                        onClick={booknow}
                      >
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BookPackage;
